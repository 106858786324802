<template>
  <div>
    <b-overlay :show="loading">
      <template v-if="!loading && is_paid">
        <b-alert
          variant="danger"
          :show="true"
        >
          <h4 class="alert-heading">
            Loan already paid.
          </h4>
          <div class="alert-body">
            You have already paid for this loan.
          </div>
        </b-alert>
      </template>

      <template v-if="balance > 0">
        <validation-observer ref="repayLoanForm">
          <b-form
            class="repay-loan mt-2"
            @submit.prevent="repayLoan"
          >
            <b-row>
              <b-col 
                class=""
                cols="12"
                xl="8"
                md="8"
              >
                <b-card 
                  no-body
                  class=""
                >
                  <b-card-body>

                    <b-card-body>
                      <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center justify-content-center flex-column mr-4 cursor-pointer">
                          <img src="@/assets/images/icons/bank.png" alt="bank-icon" class="payment-channel-icon" />
                          <p class="font-weight-bold mt-2 payment-channel-label">
                            Bank Deposit / Transfer</p>
                        </div>

                        <div class="d-flex align-items-center justify-content-center flex-column mr-4 disabled">
                          <img src="@/assets/images/icons/debit-cards.png" alt="bank-icon" class="payment-channel-icon" />
                          <p class="font-weight-bold mt-2">
                            Debit Card</p>
                        </div>

                        <div class="d-flex align-items-center justify-content-center flex-column disabled">
                          <img src="@/assets/images/icons/smartphone.png" alt="bank-icon" class="payment-channel-icon" />
                          <p class="font-weight-bold mt-2">
                            Mobile Money</p>
                        </div>
                      </div>
                    </b-card-body>
                    <hr class="invoice-spacing">

                    <b-card-body v-if="errorMessage" class="invoice-padding pt-2">
                      <b-alert
                        variant="danger"
                        :show="true"
                      >
                        <div class="alert-body font-small-2">
                          <b-card-text class="mb-2">
                            {{ errorMessage }}
                          </b-card-text>
                        </div>
                      </b-alert>
                    </b-card-body>

                    <b-card-body>
                      <p class="text-muted">
                        Loan Details</p>
                      
                      <p class="">
                        <span class="font-weight-bold">Original Loan Cost</span>: <strong>GH¢{{ formatMoney(total_loan_cost) }}</strong></p>

                      <p class="">
                        <span class="font-weight-bold">Total Repaid</span>: <strong>GH¢{{ formatMoney(total_repaid) }}</strong></p>

                      <p class="text-danger">
                        <span class="font-weight-bold">Balance Remaining</span>: <strong>GH¢{{ formatMoney(balance) }}</strong></p>
                    </b-card-body>
                    <hr class="invoice-spacing">

                    <b-card-body class="">
                      <b-form-group
                        label="Upload bank  deposit slip or evidence of transfer here (You can attach multiple screenshots)"
                        label-for="deposit_slip"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Deposit Slip / Evidence of transfer"
                          rules="required|image"
                        >
                          <b-form-file
                            id="deposit_slip"
                            v-model="deposit_slip_screenshot"
                            class="mb-2 deposit-slip-picker"
                            accept="image/*"
                            placeholder=""
                            drop-placeholder="Drop Deposit Slip / Evidence of transfer screenshot here"
                            :multiple="true"
                          ></b-form-file>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-card-body>
                  </b-card-body>
                </b-card>
              </b-col>

              <b-col
                cols="12"
                xl="4"
                md="4"
                class="bg-primary mt-md-0 mt-2"
              >
                <b-card-body class="text-white bg-primary">
                  <span class="font-weight-bold"><strong>Pay to: </strong></span>
                </b-card-body>

                <b-card-body class="pt-0 ">
                  <p class="text-white">
                    <span class="font-weight-bold">Bank Name</span>: <strong>{{ payment_details.name }}</strong></p>
                    <p class="text-white">
                      <span class="font-weight-bold">Bank Branch</span>: <strong>{{ payment_details.branch }}</strong></p>
                    <p class="text-white">
                      <span class="font-weight-bold">Account Name</span>: <strong>{{ payment_details.account_name }}</strong></p>
                  <p class="text-white">
                    <span class="font-weight-bold">Acc No</span>: <strong>{{ payment_details.account_number }}</strong></p>

                  <hr class="invoice-spacing mt-5">
                  <div>
                    <div class="d-flex justify-content-start">
                      <b-button
                        class="my-2"
                        type="submit"
                        size="lg"
                        style="padding-left: 70px; padding-right: 70px; background-color: #459042 !important; border-color: #459042 !important;"
                        :disabled="!deposit_slip_screenshot"
                        >
                          <!-- Pay off GH¢{{ formatMoney(balance) }} -->
                          Submit Payment
                        </b-button>
                    </div>
                  </div>
                </b-card-body>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </b-overlay>
  </div>
</template>

<script >
import { get } from "lodash";
import { required } from '@validations'
import {
  BOverlay, BRow, BCol, BAlert, BLink, BCard, BCardBody, 
  BButton,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BFormGroup,
  BPopover,
  BFormFile,
  BForm,
  BFormInput,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BFormCheckbox,
    BFormRadio,
    BFormGroup,
    BPopover,
    BFormFile,
    BForm,
    BFormInput,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      is_paid: false,
      total_loan_cost: 0,
      total_repaid: 0,
      balance: 0,
      agree_to_terms: false,
      errorMessage: "",

      deposit_slip_screenshot: null,

      payment_details: {
        account_name: "",
        account_number: "",
        bank_name: "",
        branch: "",
      },

      required
    }
  },
  created(){
    this.fetchLoanDetails();
  },
  methods: {
    async fetchLoanDetails(){
      try {
        this.loading = true

        const { loan_id } = this.$route.params;
        const response = await this.useJwt().clientService.fetchPayOffLoanRecord(loan_id);
        const { is_paid, balance, total_loan_cost, total_repaid, payment_details } = get(response, "data.data");

        this.is_paid = is_paid;
        this.balance = balance;
        this.total_loan_cost = total_loan_cost;
        this.total_repaid = total_repaid;
        this.payment_details = payment_details;

        console.log(this.payment_details)
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async repayLoan(){
      try {
        this.loading = true;
        this.errorMessage = '';
        
        const success = await this.$refs.repayLoanForm.validate();
        if (!success){
          return;
        }

        if (!this.deposit_slip_screenshot){
          throw new Error("Please select deposit or evidence of transfer slip.");
        }

        const confirmation_result = await this.$swal({
          title: 'Confirm?',
          text: "You are about to submit a loan repayment evidence.Proceed?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (!confirmation_result.value) return;

        const formData = new FormData();
        const { loan_id } = this.$route.params;

        if (Array.isArray(this.deposit_slip_screenshot)){
          this.deposit_slip_screenshot.forEach(slip => {
            formData.append("deposit_slip", slip);
          })
        } else {
          formData.append("deposit_slip", this.deposit_slip_screenshot);
        }

        await this.useJwt().clientService.payOffLoan(loan_id, formData);

        this.$router.replace({
          name: "client-list-manual-repayments"
        }).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: "Your loan pay-off request is being processed. We'll update you shortly."
            },
          });
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss">
  .payment-channel-icon {
    width: 50px;
  }
  .payment-channel-label {
    color: #302861;
    font-weight: bold;
  }
  .font-active {
    color: #302861
  }
  .disabled {
    opacity: 0.3;
    cursor: no-drop;
  }
    .deposit-slip-picker {
      .custom-file-label::after {
        background-color: #302861;
        color: whitesmoke;
        padding-left: 40px;
        padding-right: 40px;
      }
    }
</style>
